import Axios from 'src/services/axios';

import {
  DimensionConfigResponse,
  FloorsetConfigResponse,
  DaysRangeListResponse,
  Scope,
  RangeItem,
  FloorsetAttrResponse,
  ScopeResponse,
  PlanDatesResponse,
  DimensionHierTreeResponse,
} from 'src/types/Scope';
import { provideAppName } from 'src/utils/Domain/Perspective';
import { merge, omit } from 'lodash';
import { BasicItem, TimeSheetInfo } from 'src/worker/pivotWorker.types';
import ServiceContainer from 'src/ServiceContainer';
import { ASSORTMENT_CONTEXT, HINDSIGHTING_CONTEXT } from 'src/utils/Domain/Constants';
import { AppType } from 'src/services/configuration/codecs/bindings.types';
import { toast } from 'react-toastify';

export interface ScopeConfigResponse {
  dimensionConfig: DimensionConfigResponse;
  dimensionHierConfig: DimensionHierTreeResponse;
  rangeList: RangeItem[];
  pastRangeList: RangeItem[];
  daysList: DaysRangeListResponse;
  pastDaysList: DaysRangeListResponse;
  daysListExtended: DaysRangeListResponse;
  rangeListExtended: RangeItem[];
  timeInfo: TimeSheetInfo;
}

export interface ScopeClient {
  setScope(scope: Scope): Promise<ScopeResponse>;
  getScope(): Promise<ScopeResponse>;
  getDimensionConfig(appName: AppType): Promise<DimensionConfigResponse>;
  getDimensionHierConfig(appName: AppType): Promise<DimensionHierTreeResponse>;
  getQuickSelects(product: string, region: string): Promise<QuickSelect[]>;
  getFloorsets(product?: string): Promise<FloorsetConfigResponse>;
  getLyFloorsets(product: string): Promise<FloorsetConfigResponse>;
  getFloorsetAttributes(floorsetId: string, productId?: string): Promise<FloorsetAttrResponse>;
  getRangeList(pastOnly?: boolean, extendedRange?: boolean): Promise<DaysRangeListResponse>;
  getDaysRangeList(pastOnly?: boolean, extendedRange?: boolean): Promise<DaysRangeListResponse>;
  getScopeConfig(): Promise<ScopeConfigResponse>;
  getPlanDates(): Promise<PlanDatesResponse>;
}

const buildScopePath = (perspective: AppType) => `/api/assortment/${perspective}/scope`;
const dimensionInfoPath = '/api/data/assortment/dimensionInfo';
const dimensionHierTreesPath = '/api/data/assortment/dimensionHierTrees';
const floorsetPath = '/api/assortment/floorsets';
const quickSelectsPath = '/api/assortment/quickTimeSelect';
const lyFloorsetPath = '/api/assortment/lyFloorsets';
const floorsetAttrPath = '/api/assortment/floorsetAttributes';
const rangeListPath = '/api/assortment/rangeList';
const daysRangeListPath = '/api/assortment/daysRangeList';
const planDatesPath = '/api/data/assortment/dateContext';

export async function getPlanDates() {
  return provideAppName((appName) =>
    Axios.get(planDatesPath, {
      params: {
        appName,
      },
    })
      .then((response) => response.data.data)
      .then((data) => {
        return {
          planCurrent: data[`PLAN_CURRENT`].id,
          planStart: data[`PLAN_START`].id,
          planEnd: data[`PLAN_END`].id,
        };
      })
      .catch((error) => error)
  );
}

async function setScope(scope: Scope): Promise<ScopeResponse> {
  // TODO: remove this once we support historyFloorset
  const scopeToSend = omit(scope, 'historyFloorset', 'floorSet');
  return await provideAppName((appName) =>
    Axios.post(buildScopePath(appName), scopeToSend)
      .then((resp) => resp.data.data)
      .catch((err) => {
        const status = err.response?.status;
        if (status === 401) {
          toast.error('You do not have permission to view this scope.');
        } else {
          toast.error('An error occurred while setting the scope. Please try again.');
        }
        throw err;
      })
  );
}

async function getScope(): Promise<ScopeResponse> {
  return provideAppName((appName) =>
    Axios.get(buildScopePath(appName), {
      params: {
        appName,
      },
    }).then((response) => response.data.data)
  );
}

async function getDimensionConfig() {
  return provideAppName((appName) => {
    const path = dimensionInfoPath + '?dimensionIds=product&dimensionIds=location&appName=' + appName;
    return Axios.get(path)
      .then((response) => response.data.data)
      .catch((error) => error);
  });
}

async function getDimensionHierConfig() {
  return provideAppName((appName) => {
    const path = dimensionHierTreesPath + '?dimensionIds=product&dimensionIds=location&appName=' + appName;
    return Axios.get(path)
      .then((response) => {
        return response.data.dimensions;
      })
      .catch((error) => error);
  });
}

export type QuickSelectRegion = typeof HINDSIGHTING_CONTEXT | typeof ASSORTMENT_CONTEXT;
export interface QuickSelectItem extends BasicItem {
  slsstart: string;
  slsend: string;
  lySlsend: string;
  lySlsstart: string;
}
export interface QuickSelect {
  id: 'floorsets' | 'quick_selects';
  name: string;
  children: QuickSelectItem[];
}
async function getQuickSelects(product: string, region: QuickSelectRegion): Promise<QuickSelect[]> {
  return provideAppName((appName) =>
    Axios.get(quickSelectsPath, {
      params: {
        appName,
        product,
        region,
      },
    }).then((response) => response.data)
  );
}

async function getFloorsets(product?: string) {
  return provideAppName((appName) =>
    Axios.get(floorsetPath, {
      params: {
        appName,
        product,
      },
    })
      .then((response) => response.data.data)
      .catch((error) => error)
  );
}

async function getLyFloorsets(product: string) {
  return provideAppName((appName) =>
    Axios.get(lyFloorsetPath, {
      params: {
        appName,
        product,
      },
    })
      .then((response) => response.data.data)
      .catch((error) => error)
  );
}

async function getFloorsetAttributes(floorsetId: string, productId?: string) {
  return provideAppName((appName) =>
    Axios.get(floorsetAttrPath, {
      params: {
        appName,
        floorsetId,
        productId,
      },
    })
      .then((response) => response.data.data)
      .catch((error) => error)
  );
}

async function getRangeList(past = false, extendedRange = false) {
  return provideAppName((appName) =>
    Axios.get(rangeListPath, {
      params: {
        appName,
        dimension: 'time',
        past,
        extendedRange,
      },
    })
      .then((response) => response.data.data)
      .catch((error) => error)
  );
}

async function getDaysRangeList(pastOnly = false, extendedRange = false) {
  return provideAppName((appName) =>
    Axios.get(daysRangeListPath, {
      params: {
        appName,
        dimension: 'Time',
        pastOnly,
        extendedRange,
      },
    })
      .then((response) => response.data.data)
      .catch((error) => error)
  );
}

async function getScopeConfig() {
  return Promise.all([
    getDimensionConfig(),
    getDimensionHierConfig(),
    getRangeList(),
    getRangeList(true),
    getDaysRangeList(),
    getDaysRangeList(true),
    getRangeList(false, true),
    getDaysRangeList(false, true),
    ServiceContainer.pivotService.getMappingTimeInfo(),
  ])
    .then(
      ([
        dimensionConfig,
        dimensionHierConfig,
        rangeList,
        pastRangeList,
        daysList,
        pastDaysList,
        rangeListExtended,
        daysListExtended,
        timeInfo,
      ]) => {
        return {
          dimensionConfig,
          dimensionHierConfig,
          rangeList,
          daysList,
          pastRangeList,
          pastDaysList,
          rangeListExtended,
          daysListExtended,
          timeInfo,
        };
      }
    )
    .catch((error) => error);
}

// tslint:disable:no-any
interface getRangeListResp {
  rangeList: any;
  pastRangeList: any;
  daysRangeList: any;
  daysPastRangeList: any;
  rangeListExtended: any;
  daysRangeListExtended: any;
}

const rangeListCache: {
  [k: string]: Promise<any>;
} = {};
const rangeListStringToFunction: [string, () => Promise<any>][] = [
  ['rangeList', getRangeList],
  ['pastRangeList', () => getRangeList(true)],
  ['daysRangeList', getDaysRangeList],
  ['daysPastRangeList', () => getDaysRangeList(true)],
  ['rangeListExtended', () => getRangeList(false, true)],
  ['daysRangeListExtended', () => getDaysRangeList(false, true)],
];
export function getRangeLists(): Promise<getRangeListResp> {
  const rangePromises: Promise<any>[] = rangeListStringToFunction.map((key) => {
    if (rangeListCache.hasOwnProperty(key[0])) {
      return rangeListCache[key[0]];
    } else {
      rangeListCache[key[0]] = key[1]();
      return rangeListCache[key[0]];
    }
  });
  return Promise.all(rangePromises)
    .then(([rangeList, pastRangeList, daysRangeList, daysPastRangeList, rangeListExtended, daysRangeListExtended]) => {
      return {
        rangeList,
        pastRangeList,
        daysRangeList,
        daysPastRangeList,
        rangeListExtended,
        daysRangeListExtended,
      };
    })
    .catch((error) => error);
}

export const getMergedRangeLists = (): Promise<DaysRangeListResponse> => {
  return getRangeLists().then((resp) => {
    const { daysRangeListExtended: daysRangeList, daysPastRangeList } = resp;
    return {
      start_date: merge(daysRangeList.start_date, daysPastRangeList.start_date),
      end_date: merge(daysRangeList.end_date, daysPastRangeList.end_date),
    };
  });
};

export function makeScopeClient(): ScopeClient {
  return {
    setScope,
    getScope,
    getDimensionConfig,
    getDimensionHierConfig,
    getQuickSelects,
    getFloorsets,
    getLyFloorsets,
    getFloorsetAttributes,
    getRangeList,
    getDaysRangeList,
    getScopeConfig,
    getPlanDates,
  };
}
