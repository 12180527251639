import Modal from '@trendmicro/react-modal';
import React from 'react';
import { useCallback } from 'react';
import { Dropdown, Grid, Header, Segment, Button, DropdownItemProps, DropdownProps } from 'semantic-ui-react';

import { AppState, AppThunkDispatch } from 'src/store';
import { ReseedPlanModalOwnProps } from 'src/components/Mfp/Reseed/ReseedPlanModal';
import { connect } from 'react-redux';
import { ScopeReadyData, ServerScope, getScopeReadyData } from 'src/state/scope/Scope.types';
import { get, head, intersection, isEmpty, isEqual, isNil, mapValues, some } from 'lodash';
import { useState } from 'react';
import { useEffect } from 'react';
import { useHandleKeyPress } from 'src/utils/Component/hooks/hooks';
import { SeedInfoSelections } from 'src/components/Mfp/MfpScopeSelector/MfpScopebar.types';
import classNames from 'classnames';
import { forceRefreshGrid, getScope, seedScope, balanceScope } from '../../../state/scope/Scope.actions';
import { toast } from 'react-toastify';
import LoadingMask from 'src/components/LoadingMask/LoadingMask';
import { Command, CommandAnchorDetail, CommandDetail } from 'src/state/scope/codecs/Commands';
import { getBalanceCommands, hasCommands, getSeedCommands, isCommandSeed } from 'src/state/scope/codecs/Commands.utils';
import { commandToKey, keyToCommand } from 'src/state/scope/codecs/projections/PlanMetadataToDropdown';
import {
  useSelectedPlanId,
  useCurrentCommands,
  useSelectedTime,
  useCommandToDropdown,
  useCommandToTimeDropdown,
  useSelectedCommand,
  useHandleChangeCommand,
  useHandleChangeTime,
} from 'src/utils/Component/hooks/PlanManagement.hooks';
import './_InitializePlan.scss';
import AnchorToLabel from './AnchorToLabel';
import { SettingsByKey } from 'src/services/Settings';
import { useNavigate } from 'react-router-dom';
import { AvailableMembers } from 'src/services/Scope.client';

// Function to check plans for mustSeed: true and no seed command for error state when plans are invalid
function hasInvalidPlans(readyScope: ScopeReadyData | undefined): boolean {
  // Return true if any plan is invalid in commands array
  return some(readyScope?.commands, (plan) => {
    if (plan.mustSeed) {
      // The plan is invalid if it does NOT have a seed command when mustSeed is true
      const hasSeedCommand = some(plan.commands, isCommandSeed);
      return !hasSeedCommand; // Invalid if no seed command
    }
    // If mustSeed is false, the plan is considered valid
    return false;
  });
}

const mapStateToProps = (state: AppState) => {
  const { mfpScope: scope, settings } = state;
  const dimensionLabel = settings.dimensionLabelProperty;
  const readyScope = getScopeReadyData(scope);

  if (
    !readyScope ||
    isNil(state.settings.dimensionLabelProperty) ||
    !state.viewConfigSlice.availableMembers ||
    isEmpty(readyScope.commands)
  ) {
    return {
      scopeId: undefined,
      uninitializedPlans: undefined,
      balanceCommands: undefined,
      seedCommands: undefined,
      dimensionLabel: undefined,
      availableMembers: undefined,
      showEOP: undefined,
      isMultiScope: undefined,
      entries: undefined,
    };
  }

  // map these to id to compare to `currentAnchors` later
  const availableMemberIds = mapValues(state.viewConfigSlice.availableMembers.space, (dim) => dim.map((mem) => mem.id));
  const currentAnchors = readyScope.currentAnchors!; // we check for the existence of this above, so this ! is safe

  /** Indicates whether some permissions exist in the created scope but not `availableMembers` */
  const hasInvalidAnchors = !isEqual(
    mapValues(currentAnchors, (v, key) => {
      // here we create an intersection of the `currentAnchor` ids and the `availableMemberIds` ids,
      // ideally to show that everything in `currentAnchors` also appears as an id in `availableMemberIds`,
      // if the intersection of the two isn't equal to `currentAnchor`, then the user doesn't have permissions to
      // some product or location, and we can't let them login
      return intersection(v, availableMemberIds[key]);
    }),
    currentAnchors
  );

  // Permissions issue
  if (readyScope && hasInvalidAnchors) {
    return {
      anchorError: true,
    };
  }
  // Seeding Issue
  if (readyScope && hasInvalidPlans(readyScope)) {
    return {
      seedingError: true,
    };
  }

  // map it twice, as hasCommands doesn't return the correct type
  // filter so that we don't get empty sets of commands
  const balanceCommands = readyScope.commands
    .map(getBalanceCommands)
    .filter(hasCommands)
    .map(getBalanceCommands);
  const seedCommands = readyScope.commands
    .map(getSeedCommands)
    .filter(hasCommands)
    .map(getSeedCommands);
  const showEOP = !isEmpty(balanceCommands);

  return {
    scopeId: readyScope.mainConfig.id,
    uninitializedPlans: readyScope.mainConfig.uninitializedPlans,
    balanceCommands,
    seedCommands,
    dimensionLabel,
    availableMembers: state.viewConfigSlice.availableMembers,
    showEOP,
    isMultiScope: readyScope.isMultiScope,
    entries: settings.entriesByKey,
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    disaptchedSeedScope: (seedCommand: Command['command']) => dispatch(seedScope(seedCommand)),
    dispatchedBalanceScope: (balanceCommand: Command['command']) => {
      return dispatch(balanceScope(balanceCommand)).then(() => dispatch(forceRefreshGrid()));
    },
    getScope: (scopeId: string) => dispatch(getScope({ scopeId })),
  };
};

export interface InitializePlanPropsValueProps {
  seedCommands?: CommandAnchorDetail[] | undefined;
  balanceCommands?: CommandAnchorDetail[] | undefined;
  scopeId?: string;
  uninitializedPlans?: ServerScope['uninitializedPlans'];
  dimensionLabel?: string;
  availableMembers?: AvailableMembers;
  showEOP?: boolean;
  isMultiScope?: boolean;
  entries?: SettingsByKey;
}
export type InitializePlanPropsDispatchProps = ReturnType<typeof mapDispatchToProps>;
type InitializePlanProps = ReseedPlanModalOwnProps &
  InitializePlanPropsValueProps &
  InitializePlanPropsDispatchProps & {
    balanceTime: string;
    copyVersion: string;
    copyVersionOptions: DropdownItemProps[];
    onItemChange: (field: SeedInfoSelections, value?: number | string | undefined) => void;
    seedingError: boolean;
    anchorError: boolean;
  };

interface Step extends CommandDetail {
  active: boolean;
}

const InitializePlan = (props: InitializePlanProps) => {
  const {
    scopeId,
    loading,
    balanceCommands,
    seedCommands,
    uninitializedPlans,
    onCancel,
    onSubmit,
    getScope: dispatchGetScope,
    disaptchedSeedScope,
    dispatchedBalanceScope,
    showEOP,
    entries,
    seedingError,
    anchorError,
  } = props;
  const [mutationPending, setMutationPending] = useState(false);

  const [selectedPlanId, setSelectedPlanId] = useSelectedPlanId(seedCommands);
  const currentBalanceCommands = useCurrentCommands(balanceCommands, selectedPlanId);
  const [selectedBalanceTime, setSelectedBalanceTime] = useSelectedTime(currentBalanceCommands);
  const balanceVersionOptions = useCommandToDropdown(balanceCommands, selectedPlanId, selectedBalanceTime, entries);
  const balanceTimeOptions = useCommandToTimeDropdown(currentBalanceCommands, entries);
  const [selectedBalanceCommand, setSelectedBalanceCommand] = useSelectedCommand(currentBalanceCommands);
  const handleChangeBalanceCommand = useHandleChangeCommand(setSelectedBalanceCommand);

  const currentSeedCommands = useCurrentCommands(seedCommands, selectedPlanId);
  const [selectedSeedTime, setSelectedSeedTime] = useSelectedTime(currentSeedCommands);
  const seedVersionOptions = useCommandToDropdown(seedCommands, selectedPlanId, selectedSeedTime, entries);
  const seedTimeOptions = useCommandToTimeDropdown(currentSeedCommands, entries);
  const [selectedSeedCommand, setSelectedSeedCommand] = useSelectedCommand(currentSeedCommands);
  const handleChangeSeedCommand = useHandleChangeCommand(setSelectedSeedCommand);
  const handleChangeSeedTime = useHandleChangeTime(selectedSeedTime, setSelectedSeedTime);

  useEffect(() => {
    if (!selectedSeedTime && currentSeedCommands) {
      setSelectedSeedTime(head(currentSeedCommands.commands)?.displayTime);
    }
  }, [currentSeedCommands, selectedSeedTime, setSelectedSeedTime]);

  useEffect(() => {
    if (!selectedPlanId) {
      const planId = head(seedCommands)?.planId || head(balanceCommands)?.planId;
      setSelectedPlanId(planId);
    }
  }, [balanceCommands, seedCommands, selectedPlanId, setSelectedPlanId]);
  useEffect(() => {
    if (!selectedSeedCommand && currentSeedCommands) {
      setSelectedSeedCommand(commandToKey(head(currentSeedCommands.commands)!));
    }
  }, [
    balanceCommands,
    currentSeedCommands,
    selectedPlanId,
    selectedSeedCommand,
    setSelectedPlanId,
    setSelectedSeedCommand,
  ]);
  useEffect(() => {
    if (!selectedBalanceTime && currentBalanceCommands) {
      setSelectedBalanceTime(head(currentBalanceCommands.commands)?.displayTime);
    }
  }, [selectedBalanceTime, currentBalanceCommands]);

  const [steps, setSteps] = useState<Step[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    if (!seedCommands || !balanceCommands) return undefined;
    setSteps(
      [...seedCommands, ...balanceCommands].map((s, idx) => {
        if (idx === activeStep) {
          setSelectedPlanId(s.planId);
        }
        return {
          ...s,
          active: idx === activeStep,
        };
      })
    );
  }, [activeStep, balanceCommands, seedCommands, setSelectedPlanId]);

  /**
   * If this function returns `true`, then the user has completed all steps, and we can exit the modal
   */
  const changeStep = useCallback(
    (change: number) => {
      if (!steps) return false;
      const nextStep = activeStep + change;
      if (nextStep > steps.length) return true;
      setActiveStep(activeStep + change);
      return nextStep >= steps.length;
    },
    [activeStep, steps]
  );
  const handlePreviousButton = () => changeStep(-1);

  const handleNextButtonOnClick = useCallback(async () => {
    if (currentSeedCommands && selectedSeedCommand) {
      const maybeFoundCommand = keyToCommand(
        selectedSeedCommand,
        currentSeedCommands.commands.filter((c) => c.displayTime === selectedSeedTime)
      );
      if (!maybeFoundCommand) {
        return;
      }
      setMutationPending(true);
      const maybeSeed = await disaptchedSeedScope(maybeFoundCommand.command);
      if (maybeSeed.type === seedScope.rejected.type) {
        // failure, toast and don't go forward
        toast.error('An error occured seeding your scope');
      } else {
        if (changeStep(1) && !showEOP) {
          dispatchGetScope(scopeId!).then(() => {
            onSubmit();
          });
        }
      }
    }
    setMutationPending(false);
  }, [
    currentSeedCommands,
    selectedSeedCommand,
    disaptchedSeedScope,
    showEOP,
    changeStep,
    dispatchGetScope,
    scopeId,
    onSubmit,
    selectedSeedTime,
  ]);

  const handleSubmitBalance = useCallback(async () => {
    if (currentBalanceCommands && selectedBalanceCommand) {
      setMutationPending(true);
      const maybeFoundCommand = keyToCommand(selectedBalanceCommand, currentBalanceCommands.commands);
      if (!maybeFoundCommand) {
        return;
      }
      await dispatchedBalanceScope(maybeFoundCommand.command)
        .catch(() => {
          toast.error('An error occured copying EOP to BOP');
          throw new Error('An error occured copying EOP to BOP');
        })
        .then(() => {
          if (changeStep(1)) {
            dispatchGetScope(scopeId!).then(() => {
              onSubmit();
            });
          }
        })
        .finally(() => setMutationPending(false));
    }
  }, [
    currentBalanceCommands,
    selectedBalanceCommand,
    dispatchedBalanceScope,
    dispatchGetScope,
    scopeId,
    onSubmit,
    changeStep,
  ]);
  const handleEnterPress = useHandleKeyPress(handleSubmitBalance);

  // For the Back button on error
  const navigate = useNavigate();
  const handleBackButton = () => {
    navigate('/');
  };

  return (
    <div>
      <div className="initialize-plan">
        {seedingError || anchorError ? (
          <div className="error-message">
            {seedingError && <p>Seeding is required, but no valid seed data could be found. Please contact support.</p>}
            {anchorError && <p>Your account lacks permissions to the created resources, please contact support.</p>}
            <Button
              content="BACK"
              data-qa="initialize-btn-back"
              onClick={handleBackButton}
              style={{ display: 'block', margin: '20px auto' }}
            />
          </div>
        ) : (
          <>
            <Grid columns={1} doubling={true} stretched={true}>
              {isNil(uninitializedPlans) || isNil(balanceCommands) || isNil(seedCommands) || isEmpty(steps) ? (
                <div>
                  Please wait while your plan is prepared
                  <LoadingMask coverAll={true} />
                </div>
              ) : null}
              <Grid.Column>
                {steps.map((step, stepIdx) => {
                  if (step.commands.every((s, idx) => s.type === 'seed')) {
                    return (
                      <SeedSegment
                        key={`seed${stepIdx}`}
                        loading={loading}
                        command={step}
                        idx={`seed${stepIdx}`}
                        seedTimeOptions={seedTimeOptions}
                        selectedSeedTime={selectedSeedTime}
                        seedVersionOptions={seedVersionOptions}
                        selectedSeedCommand={selectedSeedCommand}
                        handleChangeSeedCommand={handleChangeSeedCommand}
                        handleChangeTime={handleChangeSeedTime}
                      />
                    );
                  } else if (step.commands.every((s, idx) => s.type === 'balance')) {
                    return (
                      <BalanceSegment
                        key={`balance${stepIdx}`}
                        loading={loading}
                        command={step}
                        idx={`balance${stepIdx}`}
                        balanceTimeOptions={balanceTimeOptions}
                        selectedBalanceTime={selectedBalanceTime}
                        handleChangeBalanceCommand={handleChangeBalanceCommand}
                        balanceVersionOptions={balanceVersionOptions}
                        selectedBalanceCommand={selectedBalanceCommand}
                      />
                    );
                  }
                })}
              </Grid.Column>
            </Grid>
            <Modal.Footer>
              <Button content="CANCEL" data-qa="initialize-btn-cancel" onClick={onCancel} />
              <Button
                content="PREVIOUS"
                disabled={activeStep === 0}
                data-qa="initialize-btn-previous"
                onClick={handlePreviousButton}
              />
              <Button
                content="NEXT"
                loading={mutationPending}
                data-qa="initialize-btn-next"
                className={classNames('initialize-plan-modal-button')}
                style={{
                  display: get(steps, `${activeStep}`)?.commands[0].type === 'seed' ? '' : 'none',
                }}
                onClick={handleNextButtonOnClick}
              />
              <Button
                content={steps?.length - 1 ? 'COMPLETE' : 'NEXT'}
                className={classNames('initialize-plan-modal-button')}
                style={{
                  display: get(steps, `${activeStep}`)?.commands[0].type === 'balance' ? '' : 'none',
                }}
                data-qa="initialize-btn-submit"
                onClick={handleSubmitBalance}
                loading={mutationPending}
                onKeyPress={handleEnterPress}
              />
            </Modal.Footer>
          </>
        )}
      </div>
    </div>
  );
};

const SeedSegment = (props: {
  loading: boolean;
  command: Step;
  idx: string;
  selectedSeedTime: string | undefined;
  seedTimeOptions: DropdownItemProps[];
  handleChangeTime: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  selectedSeedCommand: string | undefined;
  seedVersionOptions: DropdownItemProps[];
  handleChangeSeedCommand: (_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
}) => {
  const {
    loading,
    command,
    idx,
    selectedSeedTime,
    seedTimeOptions,
    handleChangeTime,
    selectedSeedCommand,
    seedVersionOptions,
    handleChangeSeedCommand,
  } = props;

  return (
    <Segment key={idx} className={command.active ? '' : 'init-hide'}>
      <Header as="h3" className="initialize-plan-header">
        Seed the Plan for:
        <div className="initialize-plan-header-text">
          <ul>
            <AnchorToLabel anchor={command.anchor} />
          </ul>
        </div>
      </Header>
      <div className="dropdown-group">
        <div className="dropdown-group-label">Select the Seed basis Plan Period</div>
        <Dropdown
          fluid={false}
          loading={loading}
          disabled={!selectedSeedTime}
          scrolling={true}
          icon={<i className="chevron far fa-chevron-down icon" />}
          options={seedTimeOptions}
          value={selectedSeedTime}
          onChange={handleChangeTime}
        />
      </div>
      <div className="dropdown-group">
        <div className="dropdown-group-label">Select the Seed Basis Plan Version</div>
        <Dropdown
          fluid={true}
          loading={loading}
          disabled={!selectedSeedCommand}
          icon={<i className="chevron far fa-chevron-down icon" />}
          options={seedVersionOptions}
          value={selectedSeedCommand}
          onChange={handleChangeSeedCommand}
        />
      </div>
    </Segment>
  );
};

const BalanceSegment = (props: {
  loading: boolean;
  command: Step;
  idx: string;
  balanceTimeOptions: DropdownItemProps[];
  selectedBalanceTime: string | undefined;
  handleChangeBalanceCommand: (_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  balanceVersionOptions: DropdownItemProps[];
  selectedBalanceCommand: string | undefined;
}) => {
  const {
    loading,
    command,
    idx,
    balanceTimeOptions,
    selectedBalanceTime,
    handleChangeBalanceCommand,
    balanceVersionOptions,
    selectedBalanceCommand,
  } = props;

  return (
    <Segment key={idx} className={command.active ? '' : 'init-hide'}>
      <Header as="h3" className="initialize-plan-header">
        Copy EOP to BOP for:
        <div className="initialize-plan-header-text">
          <ul>
            <AnchorToLabel anchor={command.anchor} />
          </ul>
        </div>
      </Header>
      <div className="dropdown-group">
        <div className="dropdown-group-label">Select the Plan period to Copy From</div>
        <Dropdown
          fluid={true}
          loading={loading}
          icon={<i className="chevron far fa-chevron-down" />}
          options={balanceTimeOptions}
          value={selectedBalanceTime}
          onChange={handleChangeBalanceCommand}
        />
      </div>
      <div className="dropdown-group">
        <div className="dropdown-group-label">Select the Plan Version</div>
        <Dropdown
          fluid={true}
          loading={loading}
          icon={<i className="chevron far fa-chevron-down" />}
          options={balanceVersionOptions}
          value={selectedBalanceCommand}
          onChange={handleChangeBalanceCommand}
        />
      </div>
    </Segment>
  );
};
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(InitializePlan);
